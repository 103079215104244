.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.ant-message-notice
{
  display: none;
}
.hidden{
  display: none;
}
.table-container {
  overflow-x: auto;
}

.scrollable-content {
  min-width: 1000px; /* Adjust as needed */
}

.bg {
  
  background-color: #6C7BEE;
  width: 480px;
  overflow: hidden;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 40px;
  font-family: 'Roboto';
  margin-top: 40px;
  
}

.card {
  
  background-color: white;
  width: 100%;
  float: left;
  margin-top: 40px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 80px 30px 25px 30px;
  text-align: center;
  position: relative;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
}
.card__success {
  position: absolute;
  top: -50px;
  left: 145px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #60c878;
  border: 5px solid #fff;
}
.check_icon {
  font-size: -webkit-xxx-large;
  color: white;
}
.card__cancel {
  position: absolute;
  top: -50px;
  left: 145px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #ffffff;
  border: 5px solid #fff;
}
.check_icon_cancel {
  font-size: -webkit-xxx-large;
  color: rgb(255, 0, 0);
}